(function () {
    const hamburger = {
        navToggle: document.querySelectorAll('.menuToggle'),
        navToggleInside: document.querySelectorAll('.menuToggleInside'),
        nav: document.querySelectorAll('.menu-mobile-container'),

        doToggle: function (e) {
            e.preventDefault();

            for (let i = 0; i < this.navToggle.length; i++) {
                this.navToggle[i].classList.toggle('expanded');
            }

            for (let i = 0; i < this.nav.length; i++) {
                this.nav[i].classList.toggle('expanded');
            }
        },
    };

    for (let i = 0; i < hamburger.navToggle.length; i++) {
        hamburger.navToggle[i].addEventListener('click', (e) => {
            hamburger.doToggle(e);
        });
        hamburger.navToggleInside[i].addEventListener('click', (e) => {
            hamburger.doToggle(e);
        });
    }
})();

(function () {
    const staticSubmenu = document.querySelectorAll(
        '.static-submenu.staticSubmenu'
    );

    for (let i = 0; i < staticSubmenu.length; i++) {
        document
            .getElementById(`listMenuItem-${staticSubmenu[i].id.split('-')[1]}`)
            .getElementsByClassName('submenu')[0].style.display = 'none';
    }
})();

(function () {
    const navSubmenus = [...document.querySelectorAll('.mobile-submenu')];

    navSubmenus.map((el) => {
        el.previousSibling.previousSibling.addEventListener('click', (e) => {
            e.preventDefault();
            e.target.nextSibling.nextSibling.classList.toggle('collapsed');
        });
    });
})();
